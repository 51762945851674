<template>
  <div>
    <Header v-if="_TEMPLATE==='template-3'"
        :mainTitle="header.mainTitle"
        :backHome=true
    />
    <mt-header v-if="_TEMPLATE==='template-1'  || _TEMPLATE==='template-2'" title="微信客服">
      <router-link to="/customerList/weCharList" slot="left">
        <mt-button icon="back"></mt-button>
      </router-link>
    </mt-header>
    <p>客服微信号：
      <span style="color:red">{{$route.query.account}}</span>
    </p>
    <div class="wechat_number_img">
			<img :src="cloudImageBaseUrl+url" alt="" class="img">
		</div>
  </div>
</template>
<style lang="scss" scoped type="text/css">
.wechat_number_img .img{
  width: 4.79rem;
  height: 4.79rem;
  margin: .55rem 1.35rem 5.7rem 1.36rem;
  clip-path: polygon(0 0, 99% 0%, 99% 100%, 0% 100%)
}
p {
  margin-top: 1.5rem;
  font-size: 0.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
<script>
  import { cloudImageBaseUrl } from '@/CONF.js'
export default {
    components:{
        Header: () => import('components/base/Header'),

        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
    },
  data() {
    return {
      cloudImageBaseUrl,
      url:'',
      header: {
            mainTitle:'微信客服',
        },
    };
  },
  methods: {
			loadUrl(){
        this.url = decodeURIComponent(this.$route.query.url);
        console.log(this.url);
      }
  },
  mounted() {
    this.loadUrl();
  },
};
</script>